@mixin mat-sidenav-container() {
    --ds-mat-container-left-shape: 16px;
    --ds-mat-container-right-shape: 16px;
    // stylelint-disable-next-line scss/operator-no-unspaced
    --ds-mat-container-left-sidenav-width: theme(width.4/5);
    --ds-mat-container-right-sidenav-width: theme(width.full);

    @media (screen(md)) {
        --ds-mat-container-left-shape: 0;
        --ds-mat-container-left-sidenav-width: theme(width.64);
        --ds-mat-container-right-sidenav-width: 34rem;
    }

    @media (screen(xl)) {
        --ds-mat-container-right-shape: 0;
        --ds-mat-container-left-sidenav-width: theme(width.64);
        --ds-mat-container-width: theme(width.96);
        --ds-mat-container-right-sidenav-width: calc(100% - var(--ds-mat-container-left-sidenav-width) - var(--ds-mat-container-width));
    }
}

@mixin set-mat-sidenav-container($left-sidenav-width, $right-sidenav-width) {
    --ds-mat-container-left-sidenav-width: $left-sidenav-width;
    --ds-mat-container-right-sidenav-width: $right-sidenav-width;
}
