@import '@angular/cdk/overlay/index';

.clr-picker {
    z-index: calc(#{$overlay-container-z-index} + 1);
}

.coloris-bg {
    &__substrate {
        background-clip: content-box;
        background-image: repeating-linear-gradient(45deg, #aaa 25%, transparent 25%, transparent 75%, #aaa 75%, #aaa),
            repeating-linear-gradient(45deg, #aaa 25%, #fff 25%, #fff 75%, #aaa 75%, #aaa);
        background-position:
            0 0,
            4px 4px;
        background-size: 2px 2px;
    }

    &__shadow {
        filter: drop-shadow(0 0 1px rgba(0, 0, 0, 50%));
    }
}
