@use 'sass:color';

@mixin p-ui-tooltip-shadow($color) {
    box-shadow: 0 8px 16px 0 color.adjust($color, $alpha: -0.6);
}

.p-ui-tooltip-overlay {
    @include p-ui-tooltip-shadow(black);

    border-radius: 10px;
}
