.mat-sidenav-container {
    .mat-sidenav {
        &:first-of-type {
            --mat-sidenav-container-shape: var(--ds-mat-container-left-shape);

            width: var(--ds-mat-container-left-sidenav-width);
        }

        &:last-of-type {
            --mat-sidenav-container-shape: var(--ds-mat-container-right-shape);

            width: var(--ds-mat-container-right-sidenav-width);
        }
    }
}
