/**
 * https://material.angular.io/guide/material-3
 * https://blog.angular.io/material-3-experimental-support-in-angular-17-2-8e681dde650e
 * https://github.com/angular/components/blob/main/guides/duplicate-theming-styles.md
 */
@use '@angular/material' as mat;
@use '@angular/material-experimental' as matx;
@use './ds/m3-palette' as ds;
@include mat.core;
@include mat.elevation-classes;
@include mat.app-background;

// it's tailwind and material conflict fix
.mdc-notched-outline__notch {
    border-right: none;
}

.form-density {
    @include mat.form-field-density(-4);
    @include mat.checkbox-density(-2);
    @include mat.icon-density(-2);

    .mat-mdc-text-field-wrapper {
        z-index: auto;
    }
}

.invoice-form {
    @include mat.form-field-density(-5);
    @include mat.datepicker-density(-5);
    @include mat.icon-button-density(-5);
    @include mat.form-field-overrides(
        (
            container-text-size: 0.8rem,
        )
    );
    @include mat.select-overrides(
        (
            trigger-text-size: 0.8rem,
        )
    );
}

.input-density {
    @include mat.form-field-density(-6);
}

.icon-button-density {
    @include mat.icon-button-density(-6);
}

.label-width-override {
    .mat-mdc-select-value {
        width: max-content;
    }

    .mat-mdc-select-trigger {
        width: fit-content;
        border-radius: 4px;
        background-color: #eeecff;
        padding: 3px 6px;
        gap: 10px;
        justify-content: space-between;
        display: flex;
    }
}

.theme {
    &.light {
        @include mat.core-theme(ds.$silo-light-theme);
        @include mat.system-level-typography(ds.$silo-light-theme);
        @include mat.all-component-themes(ds.$silo-light-theme);
        @include mat.system-level-colors(ds.$silo-light-theme);
        @include mat.sidenav-color(ds.$silo-light-theme);
        @include mat.datepicker-overrides(
            (
                calendar-container-elevation-shadow: rgba(0, 0, 0, 0.15) 2px 2px 3px,
            )
        );
        @include mat.table-overrides(
            (
                background-color: #fff,
                row-item-outline-color: #eee,
                row-item-container-height: 58px,
            )
        );
        @include mat.sidenav-overrides(
            (
                container-text-color: #46484f,
                container-background-color: var(--ds-color-light-500),
                content-background-color: var(--ds-color-light-500),
                content-text-color: #46484f,
                container-divider-color: var(--ds-color-light-500),
            )
        );
        @include mat.dialog-overrides(
            (
                subhead-size: 1.25rem,
                subhead-color: #46484f,
            )
        );
        @include mat.button-overrides(
            (
                protected-container-color: var(--ds-color-purple-500),
                protected-label-text-color: #fff,
                protected-ripple-color: var(--ds-color-purple-600),
            )
        );

        .no-dialog-border-radius {
            @include mat.dialog-overrides(
                (
                    container-shape: 0,
                    container-color: var(--ds-color-light-500),
                )
            );
        }

        .additional-modal {
            @include mat.dialog-overrides(
                (
                    container-color: #fff,
                )
            );
        }

        // @include mat.color-variants-backwards-compatibility(ds.$silo-light-theme);

        mat-form-field {
            &.light {
                --mat-form-field-error-text-color: #fff;
            }
        }

        button {
            &[mat-fab] {
                &.tertiary {
                    @include mat.fab-color(ds.$silo-light-theme, $color-variant: tertiary);
                }
            }

            &[mat-icon-button] {
                &.primary {
                    @include mat.icon-color(ds.$silo-light-theme, $color-variant: primary);
                }
            }
        }
    }

    &.dark {
        @include mat.all-component-colors(ds.$silo-dark-theme);
        @include mat.system-level-colors(ds.$silo-dark-theme);
    }
}

@include mat.typography-hierarchy(ds.$silo-light-theme);
