@mixin icon-button-size($button-size, $icon-size: 24px, $padding: 12px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: #{$padding};
    --mdc-icon-button-state-layer-size: #{$button-size};
    --mdc-icon-button-icon-size: #{$icon-size};
}

button {
    &[mat-icon-button],
    &[mat-mini-fab] {
        &.xs-icon-button {
            @include icon-button-size(32px, 14px, 6px);
        }

        &.sm-icon-button {
            @include icon-button-size(40px, 18px);
        }

        &.md-icon-button {
            @include icon-button-size(56px, 32px);
        }
    }
}
