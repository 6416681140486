mat-snack-bar-container.mat-mdc-snack-bar-container {
    .mat-mdc-snackbar-surface {
        max-width: 75vw;
        margin: 0 0 60px;

        @media (width >= 768px) {
            margin: 0;
        }
    }
}
