///
$ds-color-purple: #494db3;
$ds-color-orange: #f60;
$ds-color-bright: #787dff;
$ds-color-dark: #22245b;
$ds-color-light: #ebecf7;

///
$ds-color-purple-50: #ededf8;
$ds-color-purple-100: #dbdbf0;
$ds-color-purple-200: #b7b8e1;
$ds-color-purple-300: #9294d3;
$ds-color-purple-400: #6e71c4;
$ds-color-purple-500: #494db3;
$ds-color-purple-600: #3b3e91;
$ds-color-purple-700: #2c2e6d;
$ds-color-purple-800: #1e1f48;
$ds-color-purple-900: #0f0f24;
$ds-color-purple-950: #070812;

///
$ds-color-orange-50: #fff0e5;
$ds-color-orange-100: #ffe0cc;
$ds-color-orange-200: #ffc299;
$ds-color-orange-300: #ffa366;
$ds-color-orange-400: #ff8533;
$ds-color-orange-500: #f60;
$ds-color-orange-600: #cc5200;
$ds-color-orange-700: #993d00;
$ds-color-orange-800: #662900;
$ds-color-orange-900: #331400;
$ds-color-orange-950: #190a00;

///
$ds-color-bright-50: #f0f0ff;
$ds-color-bright-100: #e5e6ff;
$ds-color-bright-200: #c7c9ff;
$ds-color-bright-300: #adb0ff;
$ds-color-bright-400: #9497ff;
$ds-color-bright-500: #787dff;
$ds-color-bright-600: #2e35ff;
$ds-color-bright-700: #0007e0;
$ds-color-bright-800: #000594;
$ds-color-bright-900: #00034d;
$ds-color-bright-950: #000124;

///
$ds-color-dark-50: #e1e2f4;
$ds-color-dark-100: #c7c8ea;
$ds-color-dark-200: #9092d5;
$ds-color-dark-300: #585bc1;
$ds-color-dark-400: #373b95;
$ds-color-dark-500: #22245b;
$ds-color-dark-600: #1c1d4a;
$ds-color-dark-700: #151638;
$ds-color-dark-800: #0e0f25;
$ds-color-dark-900: #070713;
$ds-color-dark-950: #030307;

///
$ds-color-light-50: #fbfcfe;
$ds-color-light-100: #fbfcfe;
$ds-color-light-200: #f8f8fc;
$ds-color-light-300: #f4f5fb;
$ds-color-light-400: #f0f1f9;
$ds-color-light-500: #ebecf7;
$ds-color-light-600: #a8acdc;
$ds-color-light-700: #626ac0;
$ds-color-light-800: #373e8a;
$ds-color-light-900: #1c1f45;
$ds-color-light-950: #0d0f21;

@mixin color-palette() {
    ///
    --ds-color-purple-50: #{$ds-color-purple-50};
    --ds-color-purple-100: #{$ds-color-purple-100};
    --ds-color-purple-200: #{$ds-color-purple-200};
    --ds-color-purple-300: #{$ds-color-purple-300};
    --ds-color-purple-400: #{$ds-color-purple-400};
    --ds-color-purple-500: #{$ds-color-purple-500};
    --ds-color-purple-600: #{$ds-color-purple-600};
    --ds-color-purple-700: #{$ds-color-purple-700};
    --ds-color-purple-800: #{$ds-color-purple-800};
    --ds-color-purple-900: #{$ds-color-purple-900};
    --ds-color-purple-950: #{$ds-color-purple-950};

    ///
    --ds-color-orange-50: #{$ds-color-orange-50};
    --ds-color-orange-100: #{$ds-color-orange-100};
    --ds-color-orange-200: #{$ds-color-orange-200};
    --ds-color-orange-300: #{$ds-color-orange-300};
    --ds-color-orange-400: #{$ds-color-orange-400};
    --ds-color-orange-500: #{$ds-color-orange-500};
    --ds-color-orange-600: #{$ds-color-orange-600};
    --ds-color-orange-700: #{$ds-color-orange-700};
    --ds-color-orange-800: #{$ds-color-orange-800};
    --ds-color-orange-900: #{$ds-color-orange-900};
    --ds-color-orange-950: #{$ds-color-orange-950};

    ///
    --ds-color-bright-50: #{$ds-color-bright-50};
    --ds-color-bright-100: #{$ds-color-bright-100};
    --ds-color-bright-200: #{$ds-color-bright-200};
    --ds-color-bright-300: #{$ds-color-bright-300};
    --ds-color-bright-400: #{$ds-color-bright-400};
    --ds-color-bright-500: #{$ds-color-bright-500};
    --ds-color-bright-600: #{$ds-color-bright-600};
    --ds-color-bright-700: #{$ds-color-bright-700};
    --ds-color-bright-800: #{$ds-color-bright-800};
    --ds-color-bright-900: #{$ds-color-bright-900};
    --ds-color-bright-950: #{$ds-color-bright-950};

    ///
    --ds-color-dark-50: #{$ds-color-dark-50};
    --ds-color-dark-100: #{$ds-color-dark-100};
    --ds-color-dark-200: #{$ds-color-dark-200};
    --ds-color-dark-300: #{$ds-color-dark-300};
    --ds-color-dark-400: #{$ds-color-dark-400};
    --ds-color-dark-500: #{$ds-color-dark-500};
    --ds-color-dark-600: #{$ds-color-dark-600};
    --ds-color-dark-700: #{$ds-color-dark-700};
    --ds-color-dark-800: #{$ds-color-dark-800};
    --ds-color-dark-900: #{$ds-color-dark-900};
    --ds-color-dark-950: #{$ds-color-dark-950};

    ///
    --ds-color-light-50: #{$ds-color-light-50};
    --ds-color-light-100: #{$ds-color-light-100};
    --ds-color-light-200: #{$ds-color-light-200};
    --ds-color-light-300: #{$ds-color-light-300};
    --ds-color-light-400: #{$ds-color-light-400};
    --ds-color-light-500: #{$ds-color-light-500};
    --ds-color-light-600: #{$ds-color-light-600};
    --ds-color-light-700: #{$ds-color-light-700};
    --ds-color-light-800: #{$ds-color-light-800};
    --ds-color-light-900: #{$ds-color-light-900};
    --ds-color-light-950: #{$ds-color-light-950};
}
