@use './ds' as ds;

// typography
// Poppins, Open Sans, Raleway, Ubuntu Sans, Lato, Montserrat, Caveat, Shadows Into Light, Satisfy
// $font-family: 'Satisfy';

$font-family: 'Inter', sans-serif;

:root {
    --font-family: #{$font-family};

    @include ds.color-palette;
    @include ds.z-indexes;
    @include ds.mat-sidenav-container;
}

.material-symbols-outlined {
    font-variation-settings: 'FILL' 1;

    &.outlined {
        font-variation-settings: 'FILL' 0;
    }
}
