.mat-tab-group-override {
    .mat-mdc-tab-body-content {
        transform: none !important;
        transition: none !important;
    }

    .mdc-tab-indicator__content--underline {
        transition: none !important;
    }
}
