@import './mixins/mat-icon';

mat-icon {
    &.icon-size-14 {
        @include icon-size(14px);
    }
    &.icon-size-16 {
        @include icon-size(16px);
    }
    &.icon-size-18 {
        @include icon-size(18px);
    }
    &.icon-size-20 {
        @include icon-size(20px);
    }
}
