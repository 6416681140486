@mixin scrollbar-thin {
    ::-webkit-scrollbar {
        @apply h-1.5 w-1.5;
    }

    ::-webkit-scrollbar-track {
        @apply bg-ds-light-50 border-r-2;
    }

    ::-webkit-scrollbar-thumb {
        @apply bg-ds-light-500 rounded-full border-r-2;

        &:hover {
            @apply bg-ds-light-700;
        }
    }
}
